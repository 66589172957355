import { Trans } from 'gatsby-plugin-react-i18next';
import React, { ReactElement } from 'react';

import OvalIllustration from '../../../static/images/illustration-oval.inline.svg';
import FullwidthIllustration from './volcaneers/FullwidthIllustration';

interface CorporateDataItem {
  title: string | ReactElement;
  text?: string | ReactElement;
  link?: {
    href: string;
    text: string | ReactElement;
  };
}

const corporateData: CorporateDataItem[] = [
  {
    title: <Trans>Company name</Trans>,
    text: <Trans>Paima Studios LTD</Trans>
  },
  { title: <Trans>Directors</Trans>, text: <Trans>Sebastien Guillemot & Robert Kornacki</Trans> },
  {
    title: <Trans>Address</Trans>,
    text: <Trans>Floor 4, Banco Popular Building, Road Town, Tortola VG1110, British Virgin Islands</Trans>
  },
  { title: <Trans>Foundation date</Trans>, text: <Trans>5 April 2022</Trans> },
  { title: <Trans>Services</Trans>, text: <Trans>Blockchain Gaming</Trans> },
  {
    title: <Trans>Website</Trans>,
    link: {
      href: 'https://paimastudios.com/',
      text: 'https://paimastudios.com/'
    }
  },
  {
    title: <Trans>Twitter</Trans>,
    link: {
      href: 'https://twitter.com/PaimaStudios',
      text: 'https://twitter.com/PaimaStudios'
    }
  }
];

const CorporateSection = () => {
  return (
    <section id="corporate">
      <div className="container-narrow pb-[10vw] md:pb-[12vw] lg:pb-[0] relative z-30">
        <div className="pt-32 md:pt-64">
          <h2 className="font-heading font-bold text-18 leading-5 mb-16 text-center">
            <Trans>About Paima Studios</Trans>
          </h2>
          <h1 className="font-heading font-bold text-32 leading-10 md:text-48 md:leading-[57px] mb-28 md:mb-40 text-center">
            <Trans>Corporate Data</Trans>
          </h1>
        </div>
        <div className="bg-white border rounded-[12px] border-volcaneers-separator p-[12px] lg:p-[24px] mt-[40px] overflow-x-auto">
          <table className="overflow-hidden">
            <tbody>
              {corporateData.map((item, index) => (
                <tr key={index} className="border-b border-b-volcaneers-separator last-of-type:border-b-0">
                  <th className="px-[12px] py-[12px] lg:py-[16px] min-w-[100px] lg:min-w-[175px] text-left align-baseline text-16 lg:text-18 font-bold font-heading">
                    {item.title}
                  </th>
                  <td className="px-[12px] py-[12px] lg:py-[16px] text-left align-baseline text-16">
                    {item.link ? (
                      <a
                        href={item.link.href}
                        target="_blank"
                        className="underline hover:cursor-pointer text-volcaneers-secondary-light hover:text-volcaneers-secondary-default"
                      >
                        {item.link.text}
                      </a>
                    ) : (
                      item.text
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <FullwidthIllustration illustration={<OvalIllustration />} containerClasses="pt-[10vw] fill-volcaneers-black" />
    </section>
  );
};

export default CorporateSection;
